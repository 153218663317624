import * as Action from '../action-types';

const initialState = {
  questions: [],
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case Action.GET_INSPECTION_QUESTIONS:
          return { ...state, ...payload };
      default:
          return state;
    }
};
