/*************** AUTH *******************/
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const LOADING = "LOADING";

/*************** SETTINGS *******************/
export const GET_SETTING_DETAILS = 'GET_SETTING_DETAILS';

/*************** INSPECTION *******************/
export const GET_INSPECTION_QUESTIONS = 'GET_INSPECTION_QUESTIONS';
