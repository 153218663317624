import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import Auth from './Auth';
import loader from './loader';
import settings from './settings';
import inspectionForm from './inspectionForm';

export const history = createBrowserHistory();
const RootReducer = combineReducers({
  router: connectRouter(history),
  Auth,
  loader,
  settings,
  inspectionForm
});

export default RootReducer;
