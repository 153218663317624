import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import './scss/style.scss';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import store from './store';
import * as action from './store/actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages 
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const UserVerification = React.lazy(() => import('./views/pages/register/UserVerification'));
const Forgot = React.lazy(() => import('./views/pages/forgot/Forgot'));
const ForgotReset = React.lazy(() => import('./views/pages/forgot/ForgotReset'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const FormAssignCheck = React.lazy(() => import('./views/avatar/FormAssignCheck'));
const InspectionForm = React.lazy(() => import('./views/avatar/InspectionForm'));

export const history = createBrowserHistory();

store.dispatch(action.authCheck());

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
        <ToastContainer />
          <HashRouter>
              <React.Suspense fallback={loading}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                  <Route exact path="/forgot" name="Forgot Page" render={props => <Forgot {...props}/>} />
                  <Route exact path="/user/verify/:id/:hashCode" name="User Verification Page" render={props => <UserVerification {...props}/>} />
                  <Route exact path="/forgot/reset/:id/:hashCode" name="Reset Password Page" render={props => <ForgotReset {...props}/>} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route exact path="/form/:formKey" name="Check Form Assign" render={props => <FormAssignCheck {...props}/>} />
                  <Route exact path="/form/:formKey/:jwtToken" name="Inspection Form" render={props => <InspectionForm {...props}/>} />
                  <Route auth="true" path="/" name="Home" render={props => <TheLayout {...props}/>} />
                </Switch>
              </React.Suspense>
          </HashRouter>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
